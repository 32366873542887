<template>
  <v-card
    v-if="kiosk"
    flat
    tile
  >
    <v-container fluid>
      <v-form v-model="valid">
        <v-row>
          <template v-for="field in fieldsWithValues">
            <v-col
              v-if="field.show"
              :key="field.key"
              :cols="field.cols ? field.cols : '12'"
            >
              <UiField
                :value="field"
                in-col
                @change="setFieldValue"
              />
            </v-col>
          </template>
          <v-col cols="12">
            <OpeningHoursAdvanced
              :opening-hours="kiosk.openingHours"
              @update:openingHours="updateOpeningHours"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import UiField from "@/components/uiField"
import OpeningHoursAdvanced from "@/components/common/openingHours/OpeningHoursAdvanced"
import axios from 'axios'

export default {
  name: "KioskForm",
  components: {
    UiField,
    OpeningHoursAdvanced
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      valid: false,
      kiosk: null,
      fieldsWithValues: [],
      upsellGroups: []
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'organization',
          label: this.$t('organization'),
          editable: false,
          show: true,
          type: 'select',
          options: this.organizations,
          value: this.organization.uuid,
          disabled: true,
          required: true,
          rules: [this.rules.required],
          group: 'base-settings'
        },
        {
          key: 'title',
          label: this.$t('title'),
          editable: true,
          show: true,
          type: 'text',
          required: true,
          rules: [this.rules.required],
          value: null,
          group: 'base-settings'
        },
        {
          key: 'active',
          label: this.$t('active'),
          editable: false,
          show: false,
          type: 'switch',
          value: null,
          group: 'base-settings'
        },
        {
          key: 'channel',
          label: this.$t('channel'),
          editable: true,
          show: true,
          type: 'select',
          multiple: false,
          options: this.channels,
          required: true,
          rules: [this.rules.required],
          value: null,
          group: 'base-settings'
        },
        {
          key: 'locations',
          label: this.$t('locations'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          options: this.locations,
          value: null,
          group: 'base-settings'
        },
        {
          key: 'categories',
          label: this.$t('categories'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          options: this.categories,
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('limitByCategories'),
          group: 'base-settings'
        },
        {
          key: 'upsellGroups',
          label: this.$t('upsellGroups'),
          editable: true,
          show: true,
          type: 'select',
          multiple: true,
          options: this.upsellGroups.filter(group => group && group.status !== false)
            .map(group => ({
              key: group.uuid,
              label: group.label
            })),
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('selectUpsellGroupsHint'),
          group: 'base-settings'
        },
        {
          key: 'defaultLanguage',
          label: this.$t('defaultLanguage'),
          editable: true,
          show: true,
          type: 'select',
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('defaultSelectedLanguageHint'),
          options: this.defaultLanguages
        },
        {
          key: 'showPricesWithCurrencyCode',
          label: this.$t('showPricesWithCurrencyCode'),
          editable: true,
          show: true,
          type: 'switch',
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('showPricesWithCurrencyCodeHint')
        },
        {
          key: 'enableSubCategories',
          label: this.$t('enableSubCategories'),
          editable: true,
          show: true,
          type: 'switch',
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('enableSubCategoriesHint')
        },
        {
          key: 'packagingOptions',
          label: this.$t('fulfillmentOptions'),
          editable: true,
          show: true,
          type: 'select',
          value: null,
          multiple: true,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('fulfillmentOptionsHint'),
          options: this.packagingOptions
        },
        {
          key: 'payerInfoRequests',
          label: this.$t('payerInfoRequests'),
          editable: true,
          show: true,
          type: 'select',
          value: null,
          multiple: true,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('payerInfoRequestsHint'),
          options: this.payerInfoRequests
        },
        {
          key: 'allowedLanguages',
          label: this.$t('allowedLanguages'),
          editable: true,
          show: true,
          type: 'select',
          value: null,
          multiple: true,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('allowedLanguagesHint'),
          options: this.languages
        },
				{
          key: 'cardReaderPaymentMethodInstance',
          label: this.$t('cardReaderPaymentMethodInstance'),
          editable: true,
          show: true,
          type: 'select',
          value: null,
          multiple: false,
          hideDetails: false,
          persistentHint: true,
					required: false,
          hint: this.$t('payerInfoRequestsHint'),
          options: this.cardReaderPaymentMethodInstances
        },
        {
          key: 'allowDifferentPaymentMethods',
          label: this.$t('allowDifferentPaymentMethods'),
          editable: true,
          show: true,
          type: 'switch',
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('allowDifferentPaymentMethodsHint')
        },
        {
          key: 'receiptPrinter',
          label: this.$t('receiptPrinter'),
          editable: true,
          show: true,
          type: 'select',
          value: null,
          multiple: false,
          hideDetails: false,
          persistentHint: true,
					required: false,

          hint: this.$t('payerInfoRequestsHint'),
          options: this.receiptPrinters
        },
        {
          key: 'maintenanceMode',
          label: this.$t('maintenanceMode'),
          editable: true,
          show: true,
          type: 'switch',
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('maintenanceModeHint')
        },
        {
          key: 'collapseAllVariationGroups',
          label: this.$t('collapseAllVariationGroups'),
          editable: true,
          show: true,
          type: 'switch',
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('collapseAllVariationGroupsHint')
        }
      ]
    },
    channels() {
      return this.$store.getters.channelsFormatForUiFields()
    },
    locations() {
      const locations = Array.isArray(this.$store.state.locations) ? this.$store.state.locations.filter(location => location && location.active !== false) : []
      return this.$store.getters.locationsFormatForUiFields(locations)
    },
		cardReaderPaymentMethodInstances() {
			const instances = []

			if(this.$store.state.paymentMethodInstances !== null && Array.isArray(this.$store.state.paymentMethodInstances) && this.$store.state.paymentMethodInstances.length > 0) {
				return this.$store.state.paymentMethodInstances.filter(instance => instance.namespace === 'verifone' || instance.namespace === 'verifone_p').map(instance => {
					return {
						key: instance.uuid,
						label: instance.title
					}
				})
			}

			return instances
		},
    receiptPrinters() {
      if (this.$store.state.printers !== null && Array.isArray(this.$store.state.printers) && this.$store.state.printers.length > 0) {
        return this.$store.state.printers.map(printer => {
          return {
            key: printer.uuid,
            label: printer.title
          }
        })
      }
      return []
    },
    categories() {
      const categories = Array.isArray(this.$store.state.categories) ? this.$store.state.categories.filter(category => category && category.administrative !== true && category.active !== false) : []
      return this.$store.getters.categoriesFormatForUiFields(categories)
    },
    organization() {
      return this.$store.state.organization
    },
    organizations() {
      const currentOrganization = this.organization
      if(currentOrganization) {
        return [{
          key: currentOrganization.uuid,
          label: currentOrganization.label
        }]
      }
      return []
    },
    rules() {
      return this.$store.getters.rules
    },
    defaultLanguages() {
      const allowedLanguages = this.kiosk.allowedLanguages
      if(Array.isArray(allowedLanguages) && allowedLanguages.length > 0) {
        return this.languages.filter(l => l && allowedLanguages.includes(l.key))
      }
      return this.languages
    },
    languages() {
      return this.$store.getters.languagesFormatForUiFields()
    },
    packagingOptions() {
      return [
        {
          key: 'eatIn',
          label: 'Eat-In'
        },
        {
          key: 'takeAway',
          label: 'Take-Away'
        },
        {
          key: 'ticket',
          label: 'Ticket'
        }
      ]
    },
    payerInfoRequests() {
      return [
        {
          key: 'requestPayerName',
          label: 'Request Payer Name'
        },
        {
          key: 'requirePayerName',
          label: 'Require Payer Name'
        },
        {
          key: 'requestPayerPhone',
          label: 'Request Payer Phone'
        },
        {
          key: 'requirePayerPhone',
          label: 'Require Payer Phone'
        },
        {
          key: 'requestPayerEmail',
          label: 'Request Payer Email'
        },
        {
          key: 'requirePayerEmail',
          label: 'Require Payer Email'
        }
        ,
        {
          key: 'requestPayerComment',
          label: 'Request Payer Comment'
        },
        {
          key: 'requirePayerComment',
          label: 'Require Payer Comment'
        }
        ,
        {
          key: 'requestPayerSSN',
          label: 'Request Payer Social Security Number'
        },
        {
          key: 'requirePayerSSN',
          label: 'Require Payer Social Security Number'
        }        
      ]
    }
  },
  methods: {
    updateOpeningHours(value) {
      this.kiosk.openingHours = value
      this.$emit('input', this.kiosk)
    },
    setFieldValue({ field, value }) {
      this.kiosk[field.key] = value
      this.$emit('input', this.kiosk)
    },
    setFieldValues() {

      const newFields = []

      for(const index in this.fields) {

        const field = this.fields[index]
        if(field && field.key) {
          if(typeof this.kiosk[field.key] !== 'undefined') {
            field.value = this.kiosk[field.key]
            this.fields[index].value = this.kiosk[field.key]
          }
        }
        newFields.push(field)
      }
      this.fieldsWithValues = newFields
    }
  },
  watch: {
    valid(value) {
      this.$emit('valid', value)
    }
  },
  async mounted() {
    this.upsellGroups = await this.$store.dispatch('getUpsellGroups')

    this.kiosk = this.value
    if(typeof this.kiosk !== 'undefined' && this.kiosk !== null) {
      this.setFieldValues()
    }
  },
  destroyed() {
    this.kiosk = null
  }
}
</script>

<style scoped>

</style>
